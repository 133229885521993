import axios from 'axios'
import Router from 'next/router'
import { getUserToken } from './storage'
import config from './config'

const request = async ({
  fullUrl = false,
  url,
  data,
  auth = false,
  headers = {
    'Content-Type': 'application/json'
  },
  params = {},
  idToken,
  type = 'json',
  method
}) => {
  const useUrl = (fullUrl ? url : `${config.APIURL}${url}`)
  let token = await getUserToken()
  if (idToken) {
    token = idToken
  }

  switch (type) {
    case 'json': {
      headers = {
        'Content-Type': 'application/json'
      }
      break
    }
    case 'form-data': {
      headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      break
    }
    default:
  }

  if (typeof token === 'string') {
    if ((!token && auth) || (typeof token === 'object' && auth)) {
      Router.push({
        pathname: '/auth/login'
      })
      return {
        success: false,
        message: 'Unauthenticated'
      }
    }
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  let response = {}

  try {
    switch (method) {
      case 'get': {
        response = await axios.get(`${useUrl}`, {
          params: {
            ...data,
            ...params,
            device: 'web'
          },
          headers
        })
        break
      }
      case 'post': {
        response = await axios.post(`${useUrl}`, data, { params: { ...params, device: 'web' }, headers })
        break
      }
      case 'put': {
        response = await axios.put(`${useUrl}`, data, { params: { ...params, device: 'web' }, headers })
        break
      }
      case 'delete': {
        response = await axios.delete(`${useUrl}`, { data, params: { ...params, device: 'web' }, headers })
        break
      }
      default:
    }

    return Promise.resolve({
      success: true,
      ...response.data
    })
  } catch (error) {
    const { response } = error
    let msg
    let dat
    let statusCode
    let detailData = ''
    if (response && response instanceof Object) {
      const { data, statusText } = response
      statusCode = response.status
      const { detail } = data
      detailData = detail
      msg = data.message || statusText
      dat = {
        ...data
      } || {}
    } else {
      statusCode = 600
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        msg = error.message || 'Network Error'
      } else {
        msg = error
      }
    }
    return {
      success: false,
      detail: detailData,
      statusCode,
      message: msg,
      data: dat
    }
  }
}

export default request

import {
  apiHome
} from 'utils/config'
import request from 'utils/request'
import { DEFAULT_CITY_ID } from 'utils/variable'

const getHome = async ({
  storeId = DEFAULT_CITY_ID,
  page
}) => {
  return request({
    url: `${apiHome}/${storeId}`,
    data: {
      page
    },
    auth: true,
    method: 'get'
  })
}

const getVoucher = async ({
  page
}) => {
  return request({
    url: '/home-voucher',
    data: {
      page
    },
    auth: true,
    method: 'get'
  })
}

const getPartner = async ({
  page
}) => {
  return request({
    url: '/home-partner',
    data: {
      page
    },
    auth: true,
    method: 'get'
  })
}

const getHistory = async ({
  page
}) => {
  return request({
    url: '/home-history',
    data: {
      page
    },
    auth: true,
    method: 'get'
  })
}

const getExploreStore = async ({
  page
}) => {
  return request({
    url: '/home/explore-store',
    data: {
      page
    },
    auth: true,
    method: 'get'
  })
}

const getWeeklyTrend = async ({
  page
}, idToken) => {
  return request({
    url: '/home/weekly-trend',
    data: {
      page
    },
    idToken,
    auth: true,
    method: 'get'
  })
}

const getBuyAgain = async ({
  page
}) => {
  return request({
    url: '/home/buy-again-list',
    data: {
      page
    },
    auth: true,
    method: 'get'
  })
}

const getBrandList = async ({
  page
}) => {
  return request({
    url: '/home/signature-brand-list',
    data: {
      page
    },
    auth: true,
    method: 'get'
  })
}

const getCategoryList = async ({
  page
}) => {
  return request({
    url: '/home/signature-category-list',
    data: {
      page
    },
    auth: true,
    method: 'get'
  })
}

const getProductSignature = async ({
  page, lastId
}, idToken) => {
  return request({
    url: '/home/signature-product-limited',
    data: {
      page,
      lastId
    },
    idToken,
    auth: true,
    method: 'get'
  })
}

const getHomeAnnouncement = async () => {
  return request({
    url: '/home/announcement',
    auth: true,
    method: 'get'
  })
}

export {
  getHome,
  getVoucher,
  getPartner,
  getHistory,
  getExploreStore,
  getWeeklyTrend,
  getBuyAgain,
  getBrandList,
  getCategoryList,
  getProductSignature,
  getHomeAnnouncement
}

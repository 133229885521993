const params = {
  APIPROTOCOL: process.env.APIPROTOCOL || 'http',
  APIHOST: process.env.APIHOST || 'localhost',
  APIPORT: process.env.APIPORT || 3000,
  APIVERSION: process.env.APIVERSION || '',

  APIHOSTIMAGE: process.env.APIHOSTIMAGE || 'localhost',
  APIPORTIMAGE: process.env.APIPORTIMAGE || 4000,
  APIVERSIONIMAGE: process.env.APIVERSIONIMAGE || '',
  SOCKET_BASE_URL: process.env.SOCKET_BASE_URL || 'https://quick.k3mart.id:443',

  MAIN_URL: process.env.MAIN_URL || '',
  SUB_URL: process.env.SUB_URL || ''
}

params.APIURLONLY = `${params.APIPROTOCOL}://${params.APIHOST}:${params.APIPORT}`
params.APIURL = `${params.APIPROTOCOL}://${params.APIHOST}:${params.APIPORT}${params.APIVERSION}`
params.APISOCKET = `${params.SOCKET_BASE_URL}`
params.APIUPLOAD = `${params.APIPROTOCOL}://${params.APIHOSTIMAGE}:${params.APIPORTIMAGE}${params.APIVERSIONIMAGE}`

export default {
  ...params
}

export const VERSION_CODE = '1.0.1'
export const VERSION_NUMBER = 0

export const NOIMAGE = 'https://graph.k3mart.id/s3/no_image.png'

export const URL_IPHONE_CAMERA = 'https://apps.apple.com/us/app/camera/id1584216193'
export const URL_ANDROID_CAMERA = 'https://lens.google.com/uploadbyurl?url=%S'

export const production = process.env.NODE_ENV === 'production'
export const apiAuthLogin = '/auth/login'
export const apiAuthVerification = '/auth/login-verification'
export const apiAuth = '/auth'
export const apiUser = '/user'
export const apiSearch = '/search'
export const apiEvent = '/event'
export const apiLike = '/like'
export const apiAsset = '/asset'
export const apiTicket = '/ticket'
export const apiOrganizer = '/organizer'
export const apiMember = '/member'
export const apiArticle = '/article'
export const apiCategory = '/category'
export const apiProduct = '/search-product/get'
export const apiProductCode = '/search-product/get-code'
export const apiUserFav = '/userfav'
export const apiSlider = '/home/slider'
export const apiHome = '/home'
export const apiBank = '/bank'
export const apiHomeIntro = '/home/intro'
export const apiTestimonial = '/testimonial'
export const apiImage = '/image/upload'
export const apiPresentation = '/presentation/upload'
export const apiDocument = '/doc/upload'
export const apiPdf = '/pdf/upload'
export const apiUtils = '/utils'
export const apiWallet = '/wallet'
export const apiSaran = '/saran'
